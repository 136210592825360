import React, {Component} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CustomInput,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button, ModalHeader, ModalBody, ModalFooter, Modal, Spinner, Alert,
} from 'reactstrap';

import PageTitle from '../../components/PageTitle';
import {FileUpload} from "../../backend/providers/s3-upload/FileUpload";
import {StichAuth} from "../../backend/providers/stich-auth/StichAuth";
import ToolkitProvider, {CSVExport, Search} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {AgentsCollection} from "../../backend/providers/collections/AgentsCollection";
import {AgentAccountType, AgentLevel} from "../../backend/model/Agent";
import {UsersCollection} from "../../backend/providers/collections/UsersCollection";
import * as moment from "moment";
import cellEditFactory from "react-bootstrap-table2-editor";

const _fileUpload = new FileUpload();
const _stichAuth = new StichAuth();


const columnAgents = [
    {
        dataField: 'agentId',
        text: 'ACCOUNT ID',
        sort: true,
        editable: false
    },
    {
        dataField: 'type',
        text: 'TYPE',
        sort: true,
        editable: false
    },
    {
        dataField: 'name',
        text: 'NAME',
        sort: false,
        editable: false
    },
    {
        dataField: 'mobile',
        text: 'MOBILE',
        sort: true,
        editable: true
    },
    {
        dataField: 'email',
        text: 'EMAIL',
        sort: false,
        editable: false
    },
    {
        dataField: 'password',
        text: 'PASSWORD',
        sort: false,
        editable: false
    },
    {
        dataField: 'actions',
        text: 'Details',
        sort: false,
        dataFormat: cellButton,
        editable: false
    },
];
const columnCustomers = [
    {
        dataField: 'name',
        text: 'NAME',
        sort: true,
    },
    {
        dataField: 'mobile',
        text: 'MOBILE',
        sort: true,
    },
    {
        dataField: 'email',
        text: 'EMAIL',
        sort: false,
    },
    {
        dataField: 'created_at',
        text: 'DATE JOINED',
        sort: true,
    },
];


function cellButton(cell, row, enumObject, rowIndex) {
    return (
        <Button cell={cell} row={row} rowIndex={rowIndex}>View</Button>
    )
}

const defaultSorted = [
    {
        dataField: 'id',
        order: 'asc',
    },
];

const sizePerPageRenderer = ({options, currSizePerPage, onSizePerPageChange}) => (
    <React.Fragment>
        <label className="d-inline mr-1">Show</label>
        <Input type="select" name="select" id="no-entries" className="custom-select custom-select-sm d-inline col-1"
               defaultValue={currSizePerPage}
               onChange={(e) => onSizePerPageChange(e.target.value)}>
            {options.map((option, idx) => {
                return <option key={idx}>{option.text}</option>
            })}
        </Input>
        <label className="d-inline ml-1">entries</label>
    </React.Fragment>
);

class TierTwo extends Component {

    _agentCollection;
    _userCollection;
    state = {
        list: [],
        loading: false,
        selected: [],
        allowed: true,
        columns: [],
        agent: {}
    };

    addVideoFormRef;
    user;

    componentDidMount() {

        this.init()
            .then(() => {

            })
            .catch(error => console.error(error))
    }

    async init() {
        await _stichAuth.init();

        this.setState({loading: true});
        this.user = await _stichAuth.getUser();
        this._agentCollection = new AgentsCollection();
        this._userCollection = new UsersCollection()

        let user = await this._agentCollection.getOne(this.user.id)
        let allowed = true;

        console.log('user: ', user);
        console.log(user.type, AgentAccountType.MASTER_AGENT);
        if (user.accountType === AgentAccountType.MASTER_AGENT) {
            await this.getCustomers(this.user.id)
        } else if (user.accountType === AgentAccountType.SUPER_AGENT) {
            await this.getTierOne(this.user.id);
        } else {
            allowed = false;
        }

        this.setState({loading: false, allowed: allowed, agent: user})
    }

    getTierOne = async (userId) => {
        let agents = await this._agentCollection.getAgents(userId, AgentLevel.TIER_2);
        agents = agents.map((each, key) => {
            each.id = key;
            each.password = '******';
            // each['actions'] = true
            return each;
        });

        this.setState({list: agents, columns: columnAgents}, () => {
            console.log("Agents: ", this.state.list);
        })
    };

    getCustomers = async (userId) => {
        console.log('getCustomers(): ');
        let agents = await this._userCollection.getAgentCustomers(userId);
        agents = agents.map((each, key) => {
            each.id = key;
            each.password = '******';
            each.name = `${each.firstName} ${each.lastName}`
            each.created_at = moment(this.user.lastAuthActivity).format("MM-DD-YYYY  HH:mm");
            // each['actions'] = true
            return each;
        });

        this.setState({list: agents, columns: columnCustomers}, () => {
            console.log("Customers: ", this.state.list);
        })
    };


    save = async (oldValue, newValue, row, column) => {
        this.setState({editLoading: true})
        console.log('save(): ', oldValue, newValue, row, column);

        let update = {}
        update[column.dataField] = newValue;

        if (this.state.agent.accountType === AgentAccountType.AGENT) {
            let updated = await this._userCollection.update(row._id, update);
            console.log("updated: ", update, updated)
        } else {
            let updated = await this._agentCollection.update(row._id, update);
            console.log("updated: ", update, updated)
        }
        this.setState({editLoading: false})
    }

    render() {
        const {SearchBar} = Search;
        const {ExportCSVButton} = CSVExport;

        if (this.state.loading) {
            return (<Spinner className="m-2" color={'blue'}/>);
        }

        if (!this.state.allowed)
            return (
                <React.Fragment>
                    <Alert color="danger">
                        Page unavailable for this user!
                    </Alert>
                </React.Fragment>);


        return (
            <React.Fragment>
                <Row className="page-title">
                    <Col md={12}>
                        <PageTitle
                            breadCrumbItems={[
                                {label: 'Tier 02'},
                            ]}
                            title={'Tier 02'}
                        />
                    </Col>

                </Row>
                <Card>
                    <CardBody style={{backgroundColor: '#f3f4f7'}}>
                        {
                            this.state.columns && this.state.columns.length > 0 ?
                                <ToolkitProvider
                                    bootstrap4
                                    keyField="id"
                                    data={this.state.list}
                                    columns={this.state.columns}
                                    search
                                    exportCSV={{onlyExportFiltered: true, exportAll: false}}>
                                    {props => (
                                        <React.Fragment>
                                            <Row>
                                                <Col>
                                                    <SearchBar {...props.searchProps} />
                                                </Col>
                                                <Col className="text-right">
                                                    {
                                                        this.state.editLoading ?
                                                            <Spinner className="m-0" color={'blue'}/> : null
                                                    }
                                                </Col>
                                                <Col className="text-right">
                                                    <ExportCSVButton {...props.csvProps} className="btn btn-primary">
                                                        Export CSV
                                                    </ExportCSVButton>
                                                </Col>
                                            </Row>

                                            <BootstrapTable
                                                {...props.baseProps}
                                                bordered={false}
                                                defaultSorted={defaultSorted}
                                                cellEdit={cellEditFactory({
                                                    mode: 'click', afterSaveCell: this.save
                                                })}
                                                pagination={paginationFactory({
                                                    sizePerPage: 5,
                                                    sizePerPageRenderer: sizePerPageRenderer,
                                                    sizePerPageList: [{text: '5', value: 5,}, {
                                                        text: '10',
                                                        value: 10
                                                    }, {text: '25', value: 25}, {
                                                        text: 'All',
                                                        value: this.state.list.length
                                                    }]
                                                })}
                                                selectRow={{
                                                    mode: 'checkbox',
                                                    selected: this.state.selected,
                                                    onSelect: (row, isSelect, rowIndex) => {
                                                        let selected = this.state.selected;
                                                        let index = this.state.selected.indexOf(rowIndex);
                                                        if (index > -1)
                                                            selected.splice(index, 1)
                                                        else
                                                            selected.push(rowIndex);
                                                        this.setState(() => ({
                                                            selected: selected,
                                                        }));
                                                    }
                                                }}
                                                wrapperClasses="table-responsive"
                                            />
                                        </React.Fragment>
                                    )}
                                </ToolkitProvider> : null
                        }
                    </CardBody>
                </Card>

            </React.Fragment>
        );
    }
};
export default TierTwo;
