// @ts-ignore
import { S3, Endpoint} from 'aws-sdk'

export class FileUpload {


    spaceEndPoint: any;
    s3: any;

    constructor() {
        this.spaceEndPoint = new Endpoint('sgp1.digitaloceanspaces.com');
        this.s3 = new S3({
            endpoint: this.spaceEndPoint,
            accessKeyId: 'OKJVQ4UMDOSK6DSVWAWY',
            secretAccessKey: 'TZn0bQZ6HckRX0m1KO9AA6WA6LU2eIyiEnb+AJAFFPg'
        });
    }

    async upload(path: string, file: any, fileName: string) {


        return new Promise((resolve, reject) => {
            var params = {
                Body: file,
                Bucket: 'cga-live',
                Key: path + fileName,
                ACL: 'public-read'
            };

            this.s3.upload(params, function (err: any, data:any) {
                if (err)
                    reject(err)

                resolve(data);

            });
        });

    }

}
