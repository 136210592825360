import {Models} from "./Models";

export class Agent extends Models {
    id: string = "";
    agentId: string = "";
    name: string = "";
    mobile: string = "";
    email: string = "";
    userId: string = "";
    upLine: string = "";
    uniqueUrl: string = "";
    joinedDate: string = "";
    lastLogin: string = "";
    type: string = "";
    createdBy: string = "";
    accountType: string = AgentAccountType.AGENT;


    constructor(object?: any) {
        super();

        Object.assign(this, object)
    }

}

export class AgentLevel {
    static TIER_1 = 'tier-1';
    static TIER_2 = 'tier-2'
}

export class AgentAccountType {
    static SUPER_AGENT: string = 'super';
    static MASTER_AGENT: string = 'master';
    static AGENT: string = 'agent'
}
