import React, {Component} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CustomInput,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button, ModalHeader, ModalBody, ModalFooter, Modal, Spinner,
} from 'reactstrap';

import PageTitle from '../../components/PageTitle';
import {StichAuth} from "../../backend/providers/stich-auth/StichAuth";
import {AgentsCollection} from "../../backend/providers/collections/AgentsCollection";
import {UniqueUrlUtil} from "../../backend/model/UniqueUrlUtil";
import {AgentAccountType} from "../../backend/model/Agent";
import * as moment from 'moment'

const _stichAuth = new StichAuth();
const _agentCollection = new AgentsCollection();

class Profile extends Component {

    state = {
        agentId: "",
        name: "",
        mobile: "",
        email: "",
        userId: "",
        createdBy: "",
        upLine: "",
        uniqueUrl: "",
        joinedDate: "",
        lastLogin: "",
        notification: ''
    };

    addVideoFormRef;
    user;

    componentDidMount() {

        this.init()
            .then(() => {

            })
            .catch(error => console.error(error))
    }

    copy = () => {
        const textField = document.createElement('textarea');
        textField.innerText = this.state.url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        alert('URL copied to the clipboard!');
    }

    async init() {
        await _stichAuth.init();
        this.setState({loading: true});
        this.user = await _stichAuth.getUser();
        let agent = await _agentCollection.getOne(this.user.id);

        let url = '-'

        let upline = '-';
        if (agent.accountType !== AgentAccountType.SUPER_AGENT) {
            let uplineAgent = await _agentCollection.getOne(agent.createdBy);
            upline = uplineAgent.agentId;

            url = UniqueUrlUtil.generate(agent.agentId);
        }

        this.setState({
            agentId: agent.agentId,
            name: agent.name,
            mobile: agent.mobile,
            email: agent.email,
            userId: agent.userId,
            createdBy: agent.createdBy,
            upLine: upline,
            uniqueUrl: url,
            joinedDate: moment(agent.created_at).format("MM-DD-YYYY"),
            lastLogin: moment(this.user.lastAuthActivity).format("MM-DD-YYYY  HH:mm"),
            loading: false
        })
    }

    requestPasswordReset = async () => {
        this.setState({resetLoading: true})
        await _stichAuth.requestPasswordResetEmail(this.state.email);

        this.setNotification(`An email has been sent to ${this.state.email} with link to password reset.`)
        this.setState({resetLoading: false})
    };

    setNotification = (text, ghost = 3000) => {
        this.setState({notification: text}, () => {
            setTimeout(() => {
                this.setState({notification: ''})
            }, ghost)
        })
    }

    render() {
        let labelColumnSize = 2
        let fieldColumnSize = 3;


        if (this.state.loading) {
            return (<Spinner className="m-2" color={'blue'}/>);
        }


        return (
            <React.Fragment>
                <Row className="page-title">
                    <Col md={12}>
                        <PageTitle
                            breadCrumbItems={[
                                {label: 'Your Details'},
                            ]}
                            title={'Your Details'}
                        />
                    </Col>

                </Row>
                <Card>
                    <CardBody style={{backgroundColor: '#f3f4f7'}}>
                        <Row>
                            <Col>
                                <Form>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Your Agent ID</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input
                                                    style={{backgroundColor: '#e3e7f0'}}
                                                    disabled={true} value={this.state.agentId} type="text"
                                                    name="text" id="text"
                                                    placeholder="with a placeholder"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Name</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input
                                                    style={{backgroundColor: '#e3e7f0'}}
                                                    value={this.state.name} type="text" name="text" id="text"
                                                    placeholder="with a placeholder"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Mobile</Label>
                                                <br/>
                                                <small>For security reason, only last 4 number will appear</small>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input disabled={true}
                                                       value={this.state.mobile.replace(/.(?=.{4})/g, 'x')} type="text"
                                                       name="text"
                                                       id="text"
                                                       placeholder="with a placeholder"/>
                                            </Col>
                                            <Col>
                                                <Button color="primary" type="submit">
                                                    Edit
                                                </Button>
                                                <Button style={{marginLeft: 5}} color="primary" type="submit">
                                                    Update
                                                </Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Email</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input disabled={true}
                                                       style={{backgroundColor: '#e3e7f0'}}
                                                       value={this.state.email} type="text" name="text"
                                                       id="text"
                                                       placeholder="with a placeholder"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Login Password</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input type="text" name="text" id="text"
                                                       disabled={true}
                                                       value={'*********'}
                                                       placeholder="with a placeholder"/>
                                            </Col>
                                            <Col>
                                                <Button color="primary" onClick={this.requestPasswordReset} type="button">
                                                    {
                                                        this.state.resetLoading ?
                                                            <Spinner className="m-0" color={'blue'}/> :
                                                            "Request Password Reset"
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Your Up Line Is</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input
                                                    style={{backgroundColor: '#e3e7f0'}}
                                                    type="text" name="text" id="text"
                                                    value={this.state.upLine}
                                                    disabled={true}
                                                    placeholder="-"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Your Unique Url (For Player)</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input
                                                    style={{backgroundColor: '#e3e7f0'}}
                                                    type="text" name="text" id="text"
                                                    value={this.state.uniqueUrl}
                                                    placeholder="-" disabled={true}/>
                                            </Col>
                                            <Col>
                                                <Button onClick={this.copy} color="primary" type="button">
                                                    Copy
                                                </Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Joined At</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input
                                                    style={{backgroundColor: '#e3e7f0'}}
                                                    value={this.state.joinedDate} type="text" name="text" id="text"
                                                    placeholder="with a placeholder"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Last Login</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input
                                                    style={{backgroundColor: '#e3e7f0'}}
                                                    value={this.state.lastLogin} type="text" name="text" id="text"
                                                    placeholder="with a placeholder"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>


                                    <Button color="primary" type="submit">
                                        Submit
                                    </Button> </Form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </React.Fragment>
        );
    }
};
export default Profile;
