import {Base} from './Base'
import {Agent, AgentAccountType} from "../../model/Agent";

const COLLECTION_NAME = 'agents';

export class AgentsCollection extends Base<Agent> {

    constructor() {
        super(COLLECTION_NAME, Agent)
    }

    async getOne(userId: string) {
        // console.log({userId: userId});
        return await this.collection.findOne({userId: userId});
    }

    async getOneByAgentId(agentId: string) {
        return await this.collection.findOne({agentId: agentId});
    }

    async getAgents(userId: string, type: string) {
        let all = await this.collection.find({createdBy: userId, type: type}).toArray();
        all = all.map((each: any) => {
            // @ts-ignore
            let obj = new this.CLASS(each);
            obj['id'] = each._id.toString()
            return obj;
        })
        return all;
    }

    async generateSuperAgentId() {
        let superAgents = await this
            .collection
            .find({
                accountType: AgentAccountType.SUPER_AGENT
            }, {
                limit: 1,
                sort: {"created_at": -1}
            })
            .toArray();
        let lastSuperAgent = null

        if (superAgents && superAgents.length > 0) {
            lastSuperAgent = superAgents[0];
        }


        let id = 'S-AA01';
        if (lastSuperAgent) {
            let count = parseInt(lastSuperAgent.agentId.replace(/^\D+/g, ''));
            count++;
            let str = count.toString().padStart(2, '0')
            id = `S-AA${str}`
        }
        return id;
    }

    async generateMasterAgentId(agent: Agent) {
        let masterAgents = await this
            .collection
            .find({
                accountType: AgentAccountType.MASTER_AGENT, createdBy: agent.userId
            }, {
                limit: 1,
                sort: {"created_at": -1}
            })
            .toArray();
        let lastMasterAgent = null
        console.log('masterAgents: ', masterAgents);
        if (masterAgents && masterAgents.length > 0) {
            lastMasterAgent = masterAgents[0];
        }

        console.log(lastMasterAgent, {
            accountType: AgentAccountType.MASTER_AGENT, createdBy: agent.userId
        });

        let superAgentId = parseInt(agent.agentId.replace(/^\D+/g, '')).toString().padStart(2, '0')
        let id = `${superAgentId}-MA-AA01`

        if (lastMasterAgent) {
            let idBreak = lastMasterAgent.agentId.split("-");
            let masterAgentCount = parseInt(idBreak[2].replace(/^\D+/g, ''));
            console.log(masterAgentCount);
            masterAgentCount++;
            console.log(masterAgentCount);
            let str = masterAgentCount.toString().padStart(2, '0')
            id = `${superAgentId}-MA-AA${str}`
            console.log(id);
        }

        return id;
    }

    async generateAgentId(superUserId: string, masterUserId: string) {
        let superAgent = await this.getOne(superUserId);
        let lastAgent = null;
        let masterAgent = null;
        if (masterUserId) {
            masterAgent = await this.getOne(masterUserId);
            lastAgent = await this.collection.find(
                {accountType: AgentAccountType.AGENT, createdBy: masterUserId},
                {
                    limit: 1,
                    sort: {"created_at": -1}
                }
            ).toArray();
            console.log(lastAgent);
            lastAgent = lastAgent && lastAgent.length > 0 ? lastAgent[0] : null
        } else {
            lastAgent = await this.collection.find(
                {accountType: AgentAccountType.AGENT, createdBy: superUserId},
                {
                    limit: 1,
                    sort: {"created_at": -1}
                }
            ).toArray();
            console.log(lastAgent);
            lastAgent = lastAgent && lastAgent.length > 0 ? lastAgent[0] : null
        }

        console.log(lastAgent);

        let superAgentId = parseInt(superAgent.agentId.replace(/^\D+/g, '')).toString().padStart(2, '0')
        let masterAgentId = 'XX'
        if (masterUserId) {
            masterAgentId = parseInt(masterAgent.agentId.replace(/^\D+/g, '')).toString().padStart(2, '0');
        }

        let id = `${superAgentId}-${masterAgentId}-AA01`;

        if (lastAgent) {
            let idBreak = lastAgent.agentId.split("-");
            let agentCount = parseInt(idBreak[2].replace(/^\D+/g, ''));
            agentCount++;
            let str = agentCount.toString().padStart(2, '0')
            id = `${superAgentId}-${masterAgentId}-AA${str}`;
        }

        return id;
    }
}
