import React, {Component} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CustomInput,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button, ModalHeader, ModalBody, ModalFooter, Modal, Spinner,
} from 'reactstrap';
import {Plus} from 'react-feather';

import PageTitle from '../../../components/PageTitle';
import {StichAuth} from "../../../backend/providers/stich-auth/StichAuth";
import {UsersCollection} from "../../../backend/providers/collections/UsersCollection";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {CSVExport, Search} from "react-bootstrap-table2-toolkit";

const _usersCollection = new UsersCollection();
const _stichAuth = new StichAuth();

class Users extends Component {

    state = {
        list: [],
        loading: false
    };
    user;

    componentDidMount() {

        this.init()
            .then(() => {

            })
            .catch(error => console.error(error))
    }

    async init() {
        this.setState({loading: true});
        this.user = await _stichAuth.getUser();
        let list = await _usersCollection.all();

        list = list.map(each => {
            delete each._id
            // each['id'] = each._id.toString()
            return each;
        })

        console.log(list);

        this.setState({list: list, loading: false})
    }

    render() {
        const {SearchBar} = Search;
        const {ExportCSVButton} = CSVExport;
        let columns = [];
        if (this.state.list.length > 0)
            columns = Object.keys(this.state.list[0]).map(each => {
                return {
                    dataField: each,
                    text: each.toUpperCase(),
                    sort: true,
                };
            });
        return (
            <React.Fragment>
                <Row className="page-title">
                    <Col md={12}>
                        <PageTitle
                            breadCrumbItems={[
                                {label: 'Forms', path: '/forms/basic'},
                                {label: 'Form Elements', path: '/forms/basic', active: true},
                            ]}
                            title={'Users'}
                        />
                        <br/>
                    </Col>
                </Row>
                {
                    !this.state.loading && this.state.list.length > 0 ?
                        <Card>
                            <CardBody>


                                <ToolkitProvider
                                    bootstrap4
                                    keyField="id"
                                    data={this.state.list}
                                    columns={columns}
                                    search
                                    exportCSV={{onlyExportFiltered: true, exportAll: false}}>
                                    {props => (
                                        <React.Fragment>
                                            <Row>
                                                <Col>
                                                    <SearchBar {...props.searchProps} />
                                                </Col>
                                                <Col className="text-right">
                                                    <ExportCSVButton {...props.csvProps} className="btn btn-primary">
                                                        Export CSV
                                                    </ExportCSVButton>
                                                </Col>
                                            </Row>

                                            <BootstrapTable
                                                {...props.baseProps}
                                                bordered={false}
                                                // defaultSorted={defaultSorted}
                                                pagination={paginationFactory({
                                                    sizePerPage: 5,
                                                    sizePerPageRenderer: this.sizePerPageRenderer,
                                                    sizePerPageList: [{text: '5', value: 5,}, {text: '10', value: 10}, {
                                                        text: '25',
                                                        value: 25
                                                    }, {text: 'All', value: this.state.list.length}]
                                                })}
                                                wrapperClasses="table-responsive"
                                            />
                                        </React.Fragment>
                                    )}
                                </ToolkitProvider>

                            </CardBody>
                        </Card> : null
                }
                {
                    this.state.loading ?
                        <Spinner className="m-2" color={'blue'}/>
                        : null
                }

            </React.Fragment>
        );
    }


    sizePerPageRenderer = ({options, currSizePerPage, onSizePerPageChange}) => (
        <React.Fragment>
            <label className="d-inline mr-1">Show</label>
            <Input type="select" name="select" id="no-entries" className="custom-select custom-select-sm d-inline col-1"
                   defaultValue={currSizePerPage}
                   onChange={(e) => onSizePerPageChange(e.target.value)}>
                {options.map((option, idx) => {
                    return <option key={idx}>{option.text}</option>
                })}
            </Input>
            <label className="d-inline ml-1">entries</label>
        </React.Fragment>
    );

};
export default Users;
