import React, {Component} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CustomInput,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button, ModalHeader, ModalBody, ModalFooter, Modal, Spinner,
} from 'reactstrap';

import PageTitle from '../../components/PageTitle';
import {FileUpload} from "../../backend/providers/s3-upload/FileUpload";
import {StichAuth} from "../../backend/providers/stich-auth/StichAuth";
import {UsersCollection} from "../../backend/providers/collections/UsersCollection";
import {UniqueUrlUtil} from "../../backend/model/UniqueUrlUtil";
import {AgentsCollection} from "../../backend/providers/collections/AgentsCollection";
import {AgentAccountType} from "../../backend/model/Agent";

const _fileUpload = new FileUpload();
const _stichAuth = new StichAuth();

class UniqueUrl extends Component {

    state = {
        url: '',
        loading: false
    };

    user;
    _userCollection = new UsersCollection();
    _agentCollection = new AgentsCollection();

    componentDidMount() {

        this.init()
            .then(() => {

            })
            .catch(error => console.error(error))
    }

    async init() {
        this.setState({loading: true});
        this.user = await _stichAuth.getUser();
        let agent = await this._agentCollection.getOne(this.user.id);

        let url = '-'
        if (agent.accountType !== AgentAccountType.SUPER_AGENT) {
            url = UniqueUrlUtil.generate(agent.agentId);
        }
        this.setState({loading: false, url: url})
    }

    copy = () => {
        const textField = document.createElement('textarea');
        textField.innerText = this.state.url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        alert('URL copied to the clipboard!');
    }


    render() {
        let labelColumnSize = 2
        let fieldColumnSize = 3;


        if (this.state.loading) {
            return (<Spinner className="m-2" color={'blue'}/>);
        }


        return (
            <React.Fragment>
                <Row className="page-title">
                    <Col md={12}>
                        <PageTitle
                            breadCrumbItems={[
                                {label: 'Your Unique Url'},
                            ]}
                            title={'Your Unique URL'}
                        />
                    </Col>

                </Row>
                <Card>
                    <CardBody style={{backgroundColor: '#f3f4f7'}}>
                        <Row>
                            <Col>
                                <Form>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">YOUR UNIQUE URL (FOR PLAYER)</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input type="text" name="text"
                                                       id="text" value={this.state.url}
                                                       readOnly={true}
                                                       disabled={true}
                                                       style={{backgroundColor: '#eaeaea'}}
                                                       placeholder="with a placeholder"/>
                                            </Col>
                                            <Col>
                                                <Button onClick={this.copy} color="primary" type="button">
                                                    Copy
                                                </Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                </Form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </React.Fragment>
        );
    }
};
export default UniqueUrl;
