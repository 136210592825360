import {Base} from './Base'
import {User} from "../../model/User";

const COLLECTION_NAME = 'users';

export class UsersCollection extends Base<User> {

    constructor() {
        super(COLLECTION_NAME, User)
    }

    async getOne(userId: string) {
        return this.collection.findOne({userId: userId});
    }

    async getAgentCustomers(userId: string) {
        return await this.collection.find(
            {createdBy: userId},
            {sort: {created_at: -1}}
        ).toArray();
    }
}
