// @flow
import jwtDecode from 'jwt-decode';
import {Cookies} from 'react-cookie';
import {StichAuth} from "../backend/providers/stich-auth/StichAuth";

/**
 * Checks if user is authenticated
 */
const _stichAuth = new StichAuth();
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (user) {
        return true;
    }
    return false;
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    return _stichAuth.getUserNonAsync()
};


export {isUserAuthenticated, getLoggedInUser};
