import {
    Stitch,
    AnonymousCredential,
    RemoteMongoClient,
    GoogleRedirectCredential,
    FacebookRedirectCredential,
    UserPasswordCredential,
    UserPasswordAuthProviderClient
} from 'mongodb-stitch-browser-sdk'
import {Enviorment} from "../../enviorments";

export class StichAuth {
    client: any = null;

    constructor() {
        // console.log("Get Stich App: ",);
        this.init()
    }

    init() {
        try {
            this.client = Stitch.getAppClient(Enviorment.STICH.CLIENT)
        } catch (e) {
            this.client = Stitch.initializeDefaultAppClient(Enviorment.STICH.CLIENT);
        }
    }

    initCustomApp(appName: string) {
        console.log('appName: ', appName);
        this.client = Stitch.initializeAppClient(appName)
        console.log(this.client);
    }

    swtichUser = async (user: any) => {
        return await this.client.auth.switchToUserWithId(user.id);
    }

    async loginAnonymoulsy() {
        return await this.client.auth.loginWithCredential(new AnonymousCredential());
    }

    async register(email: string, password: string) {
        const emailPasswordClient = this.client.auth
            .getProviderClient(UserPasswordAuthProviderClient.factory);

        return await emailPasswordClient.registerWithEmail(email, password);
    }

    async login(email: string, password: string) {
        const credential = new UserPasswordCredential(email, password);
        return await this.client.auth.loginWithCredential(credential);
    }

    async googleLogin() {
        const credential = new GoogleRedirectCredential("http://cgalive.net/login");
        this.client.auth.loginWithRedirect(credential);
    }

    async requestPasswordResetEmail(email: string) {
        const emailPassClient = this.client.auth
            .getProviderClient(UserPasswordAuthProviderClient.factory);

        await emailPassClient.sendResetPasswordEmail(email)
    }

    async resetPassword(token: string, tokenId: string, password: string) {
        const emailPassClient = this.client.auth
            .getProviderClient(UserPasswordAuthProviderClient.factory);

        await emailPassClient.resetPassword(token, tokenId, password)
    }

    async facebookLogin() {
        const credential = new FacebookRedirectCredential("http://cgalive.net/login");
        Stitch.defaultAppClient.auth.loginWithRedirect(credential);
    }

    async handleSocialLoginRedirect() {
        return await this.client.auth.handleRedirectResult();
    }

    async logout() {
        return await this.client.auth.logout();
    }

    async getUser() {
        let user = this.client.auth.user;
        // console.log(user);
        if (user && user.loggedInProviderName === "anon-user") {
            return null;
        }
        return this.client.auth.user;
    }

    getUserNonAsync() {
        let user = this.client.auth.user;
        // console.log(user);
        if (user && user.loggedInProviderName === "anon-user") {
            return null;
        }
        return this.client.auth.user;
    }

    getMongoDb() {
        this.init()
        return this.client.getServiceClient(RemoteMongoClient.factory, "mongodb-atlas").db("app");
    }

}
