import {StichAuth} from "../stich-auth/StichAuth";
import {ObjectId} from "aws-sdk/clients/codecommit";


export class Base<Model> {

    CLASS = null;
    collection: any;
    stichAuth: StichAuth

    constructor(name: string, type: any, database = 'app') {
        this.stichAuth = new StichAuth();
        let mongodb = this.stichAuth.getMongoDb();
        this.collection = mongodb.collection(name);

        this.CLASS = type;
    }

    async all() {
        let all = await this.collection.find().toArray()
        all = all.map((each: any) => {
            // @ts-ignore
            let obj = new this.CLASS(each);
            obj['id'] = each._id.toString()
            return obj;
        })
        return all;
    }

    async one(id: string): Promise<Model> {
        let doc = await this.collection.doc(id).get();
        let object = null;
        if (doc.exists) {
            // @ts-ignore
            object = new this.CLASS(doc.data());
        }
        return object;
    }

    async delete(id: ObjectId) {
        console.log("ID: ", id);
        return await this.collection.deleteOne({_id: id});
    }

    async add(object: any): Promise<Model> {

        object['updated_at'] = new Date();
        object['created_at'] = new Date();

        let ref = this.collection.insertOne(JSON.parse(JSON.stringify(object)));
        object['id'] = ref.id;
        return object;
    }

    async update(id: ObjectId, change: any): Promise<Model> {
        change['updated_at'] = new Date();
        const update = {
            "$set": JSON.parse(JSON.stringify(change))
        };
        const query = {_id: id}
        // console.log(query, update);
        await this.collection.updateOne(query, update);
        return change;
    }

    async watch(params: object, listener: any) {
        const stream = await this.collection.watch(params);
        stream.onNext((event: any) => {
            listener(event.fullDocument);
        });
    }

    async unwatch() {

    }
}
