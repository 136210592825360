import {Models} from "./Models";

export class User extends Models {
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    mobile: string = "";
    userId: string = "";
    favorite_sports: string[] = [];
    createdBy: string = ''

}
