import React, {Component} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CustomInput,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button, ModalHeader, ModalBody, ModalFooter, Modal, Spinner, Alert
} from 'reactstrap';

import PageTitle from '../../components/PageTitle';
import {FileUpload} from "../../backend/providers/s3-upload/FileUpload";
import {StichAuth} from "../../backend/providers/stich-auth/StichAuth";
import {AgentsCollection} from "../../backend/providers/collections/AgentsCollection";
import {Agent, AgentAccountType, AgentLevel} from "../../backend/model/Agent";
import {UsersCollection} from "../../backend/providers/collections/UsersCollection";
import {User} from "../../backend/model/User";
import {Enviorment} from "../../backend/enviorments";
import TierTwo from "../tier-two/tier-two";

const _fileUpload = new FileUpload();
const _stichAuth = new StichAuth();
const _stichAuthFrontend = new StichAuth();

class Account extends Component {

    state = {
        type: AgentLevel.TIER_1,
        name: null,
        mobile: null,
        email: null,
        password: null,
        loading: false,
        error: '',
        success: '',
        accountType: '',
    };

    _agentCollection;
    _userCollection;


    componentDidMount() {
        this.init()
            .then(() => {

            })
            .catch(error => console.error(error))
    }

    init = async () => {
        await _stichAuth.init();
        try {
            await _stichAuthFrontend.initCustomApp(Enviorment.STICH.FRONTEND)
        } catch (e) {
            console.log(_stichAuthFrontend.client);
            console.error(e)
        }
        let user = await _stichAuth.getUser();
        this._agentCollection = new AgentsCollection();
        this._userCollection = new UsersCollection();

        let agent = await this._agentCollection.getOne(user.id);
        this.setState({accountType: agent.accountType})
    };

    createAccount = async (e) => {
        e.preventDefault();
        this.setState({error: ''})
        if (!this.validate()) {
            return
        }

        let currentAgent = await _stichAuth.getUser();
        console.log('currentAgent: ', currentAgent)
        try {
            this.setState({loading: true})

            let userAgent = await this._agentCollection.getOne(currentAgent.id);

            console.log('useragent: ', userAgent);

            let loggedIn = null;

            if (userAgent.accountType === AgentAccountType.AGENT || (userAgent.accountType === AgentAccountType.MASTER_AGENT && this.state.type === AgentLevel.TIER_2)) {
                console.log('_stichAuthFrontend', _stichAuthFrontend.client);
                await _stichAuthFrontend.register(this.state.email, this.state.password);
                loggedIn = await _stichAuthFrontend.login(this.state.email, this.state.password);
            } else {
                await _stichAuth.register(this.state.email, this.state.password);
                loggedIn = await _stichAuth.login(this.state.email, this.state.password);
            }

            let agent = new Agent();
            agent.name = this.state.name;
            agent.mobile = this.state.mobile;
            agent.email = this.state.email;
            agent.type = this.state.type;
            agent.userId = loggedIn.id;
            agent.createdBy = currentAgent.id;
            let createCustomer = false;
            console.log(userAgent.accountType, AgentAccountType.SUPER_AGENT);
            if (userAgent.accountType === AgentAccountType.SUPER_AGENT) {
                if (agent.type === AgentLevel.TIER_1) {
                    agent.accountType = AgentAccountType.MASTER_AGENT;
                    agent.agentId = await this._agentCollection.generateMasterAgentId(userAgent)
                } else {
                    agent.accountType = AgentAccountType.AGENT;
                    agent.agentId = await this._agentCollection.generateAgentId(currentAgent.id, null)
                }
            } else if (userAgent.accountType === AgentAccountType.MASTER_AGENT) {
                if (agent.type === AgentLevel.TIER_1) {
                    agent.accountType = AgentAccountType.AGENT;
                    agent.agentId = await this._agentCollection.generateAgentId(userAgent.createdBy, currentAgent.id)
                } else
                    createCustomer = true;
            } else {
                createCustomer = true;
            }
            console.log('agent: ', agent);
            if (!createCustomer) {
                let customer = await this._agentCollection.add(agent.toJson())
                console.log('Agent Created: ', customer);
            } else {
                let user = new User();
                user.mobile = agent.mobile;
                user.email = agent.email;
                user.userId = agent.userId;
                user.createdBy = agent.createdBy;

                let name = agent.name.split(" ");
                user.firstName = name[0];
                user.lastName = name.splice(1).join(" ")

                let createdAgent = await this._userCollection.add(user.toJson());
                console.log('Customer Created: ', createdAgent);
            }


            await _stichAuth.swtichUser(currentAgent);
            this.reset()
            this.setState({loading: false, success: 'The account has been successfully created!'})
        } catch (e) {
            console.error(e)
            this.setState({error: e.message})
            this.setState({loading: false})
            await _stichAuth.swtichUser(currentAgent);
        }

    };

    reset = () => {
        this.setState({
            name: '',
            mobile: '',
            email: '',
            password: '',
            type: null,
            userId: '',
            createdBy: ''
        })
    }

    validate = () => {
        if (!this.state.name) {
            this.setState({error: 'Name field is required'})
            return false
        }
        if (!this.state.email) {
            this.setState({error: 'Email field is required'})
            return false
        }
        if (!this.state.mobile) {
            this.setState({error: 'Mobile field is required'})
            return false
        }
        if (!this.state.password) {
            this.setState({error: 'Password field is required'})
            return false
        }
        if (!this.state.type) {
            this.setState({error: 'Select account type'})
            return false
        }

        return true
    }

    onChange = (key, event) => {
        let state = {};
        state[key] = event.target.value;
        console.log(state);
        this.setState(state);
    };

    render() {
        let labelColumnSize = 2
        let fieldColumnSize = 3;
        return (
            <React.Fragment>
                <Row className="page-title">
                    <Col md={12}>
                        <PageTitle
                            breadCrumbItems={[
                                {label: 'Create An Account'},
                            ]}
                            title={'Your Details'}
                        />
                    </Col>

                </Row>
                <Card>
                    <CardBody style={{backgroundColor: '#f3f4f7'}}>
                        <Row>
                            <Col>
                                {
                                    this.state.error ?
                                        <Alert color="danger">
                                            {this.state.error}
                                        </Alert> : null
                                }
                                {
                                    this.state.success ?
                                        <Alert color="success">
                                            {this.state.success}
                                        </Alert> : null
                                }

                                <Form onSubmit={this.createAccount}>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Type</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <FormGroup tag="fieldset">
                                                    <Row>
                                                        <FormGroup style={{marginLeft: 20}} check>
                                                            <Label check>
                                                                <Input
                                                                    checked={this.state.type === AgentLevel.TIER_1}
                                                                    onChange={(e) => {
                                                                        this.onChange('type', e)
                                                                    }}
                                                                    type="radio" value={AgentLevel.TIER_1}
                                                                    name="radio1"/>{' '}
                                                                Tier-1
                                                            </Label>
                                                        </FormGroup>
                                                        {
                                                            this.state.accountType !== AgentAccountType.AGENT ?
                                                                <FormGroup style={{marginLeft: 20}} check>
                                                                    <Label check>
                                                                        <Input
                                                                            checked={this.state.type === AgentLevel.TIER_2}
                                                                            onChange={(e) => {
                                                                                this.onChange('type', e)
                                                                            }}
                                                                            type="radio" value={AgentLevel.TIER_2}
                                                                            name="radio1"/>{' '}
                                                                        Tier-2
                                                                    </Label>
                                                                </FormGroup> : null
                                                        }
                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Account ID</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input
                                                    style={{backgroundColor: '#e3e7f0'}} type="text" name="text"
                                                    id="text" disabled={true}
                                                    placeholder="AUTO-GENERATE"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Name</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input
                                                    onChange={(e) => {
                                                        this.onChange('name', e)
                                                    }}
                                                    value={this.state.name}
                                                    type="text" name="text" id="text"
                                                    placeholder="Tom Garey"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Mobile</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input
                                                    onChange={(e) => {
                                                        this.onChange('mobile', e)
                                                    }}
                                                    value={this.state.mobile}
                                                    type="text" name="mobile" id="text"
                                                    placeholder="+65998877666"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Email</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input
                                                    onChange={(e) => {
                                                        this.onChange('email', e)
                                                    }}
                                                    value={this.state.email}
                                                    type="email" name="email" id="text"
                                                    placeholder="name@example.com"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">Password</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input
                                                    onChange={(e) => {
                                                        this.onChange('password', e)
                                                    }}
                                                    value={this.state.password}
                                                    type="text" name="password" id="text"
                                                    placeholder=""/>
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    <Button color="primary" type="submit">
                                        {
                                            this.state.loading ?
                                                <Spinner className="m-2" color={'blue'}/>
                                                : "Add New Account"
                                        }

                                    </Button>
                                    <br/>
                                    <br/>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={labelColumnSize}>
                                                <Label for="text">UNIQUE URL (FOR AGENT ONLY)</Label>
                                            </Col>
                                            <Col lg={fieldColumnSize}>
                                                <Input
                                                    style={{backgroundColor: '#e3e7f0'}}
                                                    type="text" name="text" id="text" disabled={true}
                                                    placeholder="AUTO-GENERATE"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Form>


                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </React.Fragment>
        );
    }
};
export default Account;
